body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ------------------- */

@import url("https://fonts.googleapis.com/css?family=Roboto|Staatliches");

.App-header {
  background-color: #282c34;
  height: 90vh;
  font-size: calc(10px + 2vmin);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 5vh;
}

@media (min-width: 65em) {
  .App-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

/* ------------- */

.footer {
  position: fixed;
  left: 0;
  bottom: 0;

  width: 100vw;
  background-color: #0a101b;
  color: whitesmoke;
  
  height: 5vh;
  font-size: 3vh;
  display: flex;

  align-items: center;

  font-family: "Staatliches", serif;
}

.footerText {
  position: fixed;
  right: 15px;
}

.footerHint {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.socialButton {
  margin: 0.15em;
  float: right;
  position: relative;
  align-items: center;
}

.header {
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  background-color: #0a101b;
  color: whitesmoke;
  
  height: 5vh;
  font-size: 3vh;
  display: flex;

  align-items: center;
  justify-content: center;

  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  z-index: 10;
}

.headerText {
  font-size: calc(5px + 1.8vmin);
}

/* ------------- */

.row {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 65em) {
  .row {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.video_container{
  position: absolute;
  width: auto;
  height: 40vh;
  max-width: 90vw;
}

@media (min-width: 65em) {
  .video_container {
    position: absolute;
    width: 40vw;
    height: auto;
    max-height: 90vh;
  }
}

.stack-top{
  z-index: 9;
}